var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex justify-center" },
    [
      _c(
        "v-row",
        [
          _vm.warnings.length > 0 || _vm.errors.length > 0
            ? _c(
                "v-col",
                [
                  _vm._l(_vm.errors, function(error, index) {
                    return _c(
                      "p",
                      {
                        key: index,
                        staticClass: "red--text font-weight-bold text-left"
                      },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "mr-1", attrs: { color: "error" } },
                          [
                            _vm._v(
                              "\n          mdi-alert-circle-outline\n        "
                            )
                          ]
                        ),
                        _vm._v("\n        " + _vm._s(error) + "\n      ")
                      ],
                      1
                    )
                  }),
                  _vm._l(_vm.warnings, function(warning, index) {
                    return _c(
                      "p",
                      {
                        key: index,
                        staticClass: "amber--text font-weight-bold text-left"
                      },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "mr-1", attrs: { color: "amber" } },
                          [_vm._v("\n          mdi-alert-outline\n        ")]
                        ),
                        _vm._v("\n        " + _vm._s(warning) + "\n      ")
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              staticClass: "py-0 d-flex justify-center align-center",
              attrs: { cols: "12" }
            },
            [
              _c("v-select", {
                staticClass: "px-2 pt-3 input-max-width",
                attrs: {
                  items: _vm.devices,
                  dense: "",
                  color: "primary",
                  "item-color": "primary",
                  label: "Device"
                },
                on: { change: _vm.changedDevice },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              }),
              _vm.splitId
                ? _c("v-divider", {
                    staticClass: "mx-4 d-none d-sm-flex",
                    attrs: { inset: "", vertical: "" }
                  })
                : _vm._e(),
              _vm.splitId
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var onTooltip = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mx-1",
                                      attrs: { icon: "", color: "success" },
                                      on: { click: _vm.downloadSplit }
                                    },
                                    onTooltip
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-file-download")])],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4268973825
                      )
                    },
                    [_c("span", [_vm._v("Download Creative")])]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("v-col", { staticClass: "py-0", attrs: { cols: "12" } }, [
            _vm.selected !== 0
              ? _c("div", { staticClass: "device-wrapper" }, [
                  _c(
                    "div",
                    {
                      staticClass: "phone",
                      style:
                        "width: " +
                        (_vm.selectedDevice ? _vm.selectedDevice.width : "0") +
                        "px; height: " +
                        (_vm.selectedDevice ? _vm.selectedDevice.height : "0") +
                        "px;",
                      attrs: { id: "phone_1" }
                    },
                    [
                      _c("iframe", {
                        attrs: { id: "frame_1", srcdoc: _vm.html }
                      })
                    ]
                  )
                ])
              : _c("iframe", {
                  staticClass: "frame_2",
                  attrs: { srcdoc: _vm.html }
                })
          ]),
          _vm.textAccessibility
            ? _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c("v-divider", { staticClass: "mt-4 mb-6" }),
                  _vm.selected !== 0
                    ? _c("div", { staticClass: "device-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "phone text-accessibility",
                            style:
                              "width: " +
                              (_vm.selectedDevice
                                ? _vm.selectedDevice.width
                                : "0") +
                              "px; height: " +
                              (_vm.selectedDevice
                                ? _vm.selectedDevice.height
                                : "0") +
                              "px;"
                          },
                          [
                            _c("v-textarea", {
                              staticClass: "mt-0",
                              attrs: {
                                readonly: "",
                                "no-resize": "",
                                "full-width": "",
                                "hide-details": "",
                                height: _vm.selectedDevice
                                  ? _vm.selectedDevice.height - 120
                                  : 0,
                                rows:
                                  (_vm.selectedDevice
                                    ? _vm.selectedDevice.height - 120
                                    : 1) / 28
                              },
                              model: {
                                value: _vm.textAccessibility,
                                callback: function($$v) {
                                  _vm.textAccessibility = $$v
                                },
                                expression: "textAccessibility"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _c("v-textarea", {
                        staticClass: "mb-4",
                        attrs: {
                          readonly: "",
                          "no-resize": "",
                          outlined: "",
                          "hide-details": "",
                          height: "612"
                        },
                        model: {
                          value: _vm.textAccessibility,
                          callback: function($$v) {
                            _vm.textAccessibility = $$v
                          },
                          expression: "textAccessibility"
                        }
                      })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }