<template>
  <div
    class="d-flex justify-center"
  >
    <v-row>
      <v-col v-if="warnings.length > 0 || errors.length > 0">
        <p
          v-for="(error, index) in errors"
          :key="index"
          class="red--text font-weight-bold text-left"
        >
          <v-icon
            color="error"
            class="mr-1"
          >
            mdi-alert-circle-outline
          </v-icon>
          {{ error }}
        </p>
        <p
          v-for="(warning, index) in warnings"
          :key="index"
          class="amber--text font-weight-bold text-left"
        >
          <v-icon
            color="amber"
            class="mr-1"
          >
            mdi-alert-outline
          </v-icon>
          {{ warning }}
        </p>
      </v-col>
      <v-col
        cols="12"
        class="py-0 d-flex justify-center align-center"
      >
        <v-select
          v-model="selected"
          :items="devices"
          dense
          color="primary"
          item-color="primary"
          label="Device"
          class="px-2 pt-3 input-max-width"
          @change="changedDevice"
        />
        <v-divider
          v-if="splitId"
          class="mx-4 d-none d-sm-flex"
          inset
          vertical
        />
        <v-tooltip
          v-if="splitId"
          top
        >
          <template v-slot:activator="{ on: onTooltip }">
            <v-btn
              icon
              color="success"
              class="mx-1"
              v-on="onTooltip"
              @click="downloadSplit"
            >
              <v-icon>mdi-file-download</v-icon>
            </v-btn>
          </template>
          <span>Download Creative</span>
        </v-tooltip>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <div
          v-if="selected !== 0"
          class="device-wrapper"
        >
          <div
            id="phone_1"
            class="phone"
            :style="`width: ${selectedDevice ? selectedDevice.width : '0'}px; height: ${selectedDevice ? selectedDevice.height : '0'}px;`"
          >
            <iframe
              id="frame_1"
              :srcdoc="html"
            />
          </div>
        </div>
        <iframe
          v-else
          class="frame_2"
          :srcdoc="html"
        />
      </v-col>
      <v-col
        v-if="textAccessibility"
        cols="12"
        class="py-0"
      >
        <v-divider class="mt-4 mb-6" />
        <div
          v-if="selected !== 0"
          class="device-wrapper"
        >
          <div
            class="phone text-accessibility"
            :style="`width: ${selectedDevice ? selectedDevice.width : '0'}px; height: ${selectedDevice ? selectedDevice.height : '0'}px;`"
          >
            <v-textarea
              v-model="textAccessibility"
              readonly
              no-resize
              full-width
              hide-details
              :height="selectedDevice ? selectedDevice.height - 120 : 0"
              class="mt-0"
              :rows="(selectedDevice ? selectedDevice.height - 120 : 1) / 28"
            />
          </div>
        </div>
        <v-textarea
          v-else
          v-model="textAccessibility"
          readonly
          no-resize
          outlined
          hide-details
          height="612"
          class="mb-4"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { azBlobService, nowwService } from '@/shared/services'
import { downloadFileMixin } from '@/shared/mixins/general'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'SplitDevicePreview',

  mixins: [downloadFileMixin],

  props: {
    html: {
      type: String,
      default: null,
      required: true
    },
    textAccessibility: {
      type: String,
      default: null
    },
    initMobile: {
      type: Boolean,
      default: false,
      required: false
    },
    splitId: {
      type: String,
      default: null,
      required: false
    },
    noDesktop: {
      type: Boolean,
      default: false,
      required: false
    },
    errors: {
      type: Array,
      default: () => ([]),
      required: false
    },
    warnings: {
      type: Array,
      default: () => ([]),
      required: false
    }
  },

  data () {
    return {
      selected: 0,
    }
  },

  computed: {
    ...mapGetters('appConfig', ['getDevicePreviewSizes']),
    devices () {
      const deviceSizesCopy = this.getDevicePreviewSizes().map(x => x)
      if (this.noDesktop) {
        return deviceSizesCopy
      } else {
        return [
          { value: 0, text: 'Desktop', width: 1920, height: 612 },
          ...deviceSizesCopy
        ]
      }
    },
    selectedDevice () {
      return this.devices.find(x => x.value === this.selected)
    }
  },

  created () {
    if (this.initMobile || this.noDesktop) {
      this.selected = 1
    }
  },

  methods: {
    changedDevice (device) {
      this.$emit('change', this.selectedDevice)
    },
    async downloadSplit () {
      var _this = this
      await azBlobService
        .downloadSplit(this.splitId)
        .then(function (resp) {
          _this.$_downloadFileMixin_downloadResponse(resp)
        })
    },
  }
}
</script>

<style lang="scss">
  @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,300);

  html{
    height:100%;
  }

  body {
    font-family: 'Open Sans', sans-serif;
    height:100%;
    color:#fff;
    background: #1b1b1b!important;
  }

  #container{
    background: #1b1b1b!important;
  }

  /*Basic Phone styling*/

  .phone {
    border: 40px solid #121212;
    border-width: 55px 7px;
    border-radius: 40px;
    margin: 5px auto;
    overflow: hidden;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
          -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
    -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
            transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    box-shadow: 0px 3px 0 #000, 0px 4px 0 #000, 0px 5px 0 #000, 0px 7px 0 #000, 0px 10px 20px #000;
  }

  .frame_2 {
    min-height: 612px;
    width: 100%;
    border: 1px solid #CCCCCC;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    background-clip: padding-box;
  }

  .phone iframe, .phone .v-textarea {
    border: 0;
    width: 100%;
    height: 100%;
    // background-color:#000;
  }

  .phone.text-accessibility textarea {
    margin-left: 12px;
    margin-bottom: 12px;
  }

  @media (max-width:900px) {
    .device-wrapper {
      -webkit-transform: scale(0.8, 0.8);
              transform: scale(0.8, 0.8);
    }
  }

  @media (max-width:700px) {
    .device-wrapper {
      -webkit-transform: scale(0.6, 0.6);
              transform: scale(0.6, 0.6);
    }
    .phone { margin: 0 0 0 -70px; }
  }

  @media (max-width:500px) {
    .device-wrapper {
      -webkit-transform: scale(0.4, 0.4);
              transform: scale(0.4, 0.4);
    }
  }

  /* Fade In Animation */

  @keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }
</style>
